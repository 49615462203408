import { useAsyncFn, useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  AsyncSelect,
  Button,
  ButtonRadioInput,
  Card,
  Checkbox,
  CoinInput,
  ConditionSeparator,
  DatePickerInput,
  DynamicBackButton,
  FileInput,
  Form,
  Group,
  GroupHeader,
  GroupIcon,
  InputWithLabel,
  Loading,
  LoadingButton,
  LoadingIndicator,
  Modal,
  NumberInput,
  PageHeading,
  ProductImage,
  PromotionArticle,
  PromotionArticleTag,
  PromotionSegments,
  RuleGroupItem,
  RuleGroupSeparator,
  RuleItem,
  Select,
  SubmitError,
  ValidatedField,
  ValidationMessage,
  useModal
} from '@binhatch/ui';
import { ensureArray, fromPromotion, getAllFromApi, toPromotion, uploadFile } from '@binhatch/utility';
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { BellIcon, CalendarIcon, PhotoIcon, PuzzlePieceIcon, StarIcon, UsersIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import dayjs from 'dayjs';
import {
  BeneficiaryKind,
  BonusMu,
  Condition,
  NotificationChannel,
  NotificationKind,
  ProductKind,
  ProductStatus,
  ProductUsage,
  PromotionNotificationConfig,
  Repetition,
  Rule,
  RuleGroupState,
  RuleKind,
  TargetMu
} from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';

import { productApi, promotionApi } from '@/integrations/api';
import { urls } from '@/utils/url';

import { SelectSegmentModal } from './modals/SelectSegmentModal';
import { UpdateRuleModal } from './modals/UpdateRuleModal';

type RecursivePartial<T> = {
  [x in keyof T]?: T[x] extends object ? RecursivePartial<T[x]> : T[x];
};

const WITHOUT_REPETITION = 'none' as const;

const schema = yup
  .object({
    name: yup.string().required().label(translations.fields.promotionName.label),
    description: yup.string().required().label(translations.fields.promotionDescription.label),
    imageURL: yup.string().required().label(translations.fields.promotionImage.label),
    brandLogoURL: yup.string().required().label(translations.fields.promotionLogo.label),
    beneficiaryKind: yup.mixed<BeneficiaryKind>().oneOf(Object.values(BeneficiaryKind)).required().label(translations.fields.promotionAudienceType.label),
    segments: yup.array().required().label(translations.fields.promotionAddAudience.label),
    startAt: yup.date().required().label(translations.fields.promotionPeriodStart.label),
    endAt: yup.date().required().min(yup.ref('startAt')).label(translations.fields.promotionPeriodEnd.label),
    repetition: yup.mixed<Repetition | typeof WITHOUT_REPETITION>().label(translations.fields.promotionAudienceType.label),
    ruleGroupsState: yup.boolean().default(false),
    ruleGroups: yup
      .array()
      .of(
        yup
          .object({
            rules: yup.array().of(yup.mixed<Rule>().required()).min(1).required().label(translations.fields.promotionRules.label)
          })
          .required()
      )
      .min(1)
      .required(),
    repeatable: yup.boolean().default(false),
    repeatCount: yup.number(),
    target: yup
      .object({
        targetMu: yup.mixed<TargetMu>().oneOf(Object.values(TargetMu)).required().label(translations.fields.unit.label),
        levels: yup
          .array()
          .of(
            yup
              .object({
                value: yup.number().required().label(translations.fields.promotionTargetQuantity.label),
                bonus: yup
                  .object({
                    value: yup
                      .number()
                      .when('mu', { is: (value: BonusMu) => value === BonusMu.Points, then: (s) => s.required() })
                      .label(translations.fields.promotionTargetPoint.label),
                    ref: yup
                      .string()
                      .when('mu', { is: (value: BonusMu) => value === BonusMu.Product, then: (s) => s.required() })
                      .label(translations.fields.promotionTargetProduct.label),
                    mu: yup.mixed<BonusMu>().oneOf(Object.values(BonusMu)).required().label(translations.fields.promotionTargetBonus.label)
                  })
                  .required()
              })
              .required()
          )
          .required()
      })
      .required(),
    tags: yup.mixed<Record<string, string>>().required(),
    notificationConfig: yup.mixed<PromotionNotificationConfig>().required()
  })
  .required();

interface ImageUploadProps {
  src?: string;
  children(o: { uploading: boolean; upload(file: File): Promise<string> }): React.ReactNode;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ src, children }) => {
  const [{ loading, error }, upload] = useAsyncFn(async (file: File) => {
    const { getURL, uploadURL, additionalHeaders } = await productApi.createUploadURL().then((r) => r.data);

    await uploadFile({ url: uploadURL, file, headers: additionalHeaders });

    return getURL;
  });

  return (
    <div className="flex items-center gap-4">
      <div className="border-border/50 relative w-16 flex-shrink-0 rounded border">
        <ProductImage {...{ src }} square />

        {!!error && <div className="bg-error/20 absolute inset-0" />}

        {loading ? (
          <Loading className="absolute inset-0 flex items-center justify-center" visible>
            <LoadingIndicator className="h-5 w-5" />
          </Loading>
        ) : (
          !src && (
            <PhotoIcon
              className={classNames('absolute left-1/2 top-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2', error ? 'text-error' : 'text-gray-400')}
            />
          )
        )}
      </div>

      {children({ uploading: loading, upload })}
    </div>
  );
};

const toRuleGroupState = (visible: boolean) => (visible ? RuleGroupState.Visible : RuleGroupState.Hidden);
const fromRuleGroupState = (state?: RuleGroupState) => state === RuleGroupState.Visible;

interface Props {
  clone?: boolean;
}

export const PromotionUpdatePage: React.FC<Props> = ({ clone }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { promotionId } = useParams();

  const promotion = useRemoteData({ key: 'usePromotion', promotionId, skip: !promotionId }, ({ promotionId }) => {
    if (!promotionId) throw new Error('Promotion id is missing.');

    return promotionApi
      .getPromotion(promotionId)
      .then((r) => r.data)
      .then(fromPromotion);
  });

  const onSubmit = React.useCallback(
    async ({ beneficiaryKind, startAt, endAt, repetition, ruleGroupsState, target, ...values }: yup.InferType<typeof schema>) => {
      const value = {
        ...values,
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
        repetition: repetition === WITHOUT_REPETITION ? undefined : repetition,
        ruleGroupsState: toRuleGroupState(ruleGroupsState),
        target: {
          targetMu: target.targetMu,
          levels: target.levels.map((level) => ({
            ...level,
            value: level.value.toString(),
            bonus: {
              ...level.bonus,
              value: level.bonus.mu === BonusMu.Points ? level.bonus.value ?? 0 : 0,
              ref: level.bonus.mu === BonusMu.Product ? level.bonus.ref : undefined
            }
          }))
        }
      };

      const id =
        promotionId && !clone
          ? await promotionApi.updatePromotion(promotionId, toPromotion(value)).then((r) => r.data.id)
          : await promotionApi.createPromotion(toPromotion({ beneficiaryKind, ...value })).then((r) => r.data.id);

      navigate(urls.promotions.getOne({ promotionId: id }), { state: { from: 1 }, replace: true });
    },
    [promotionId, clone, navigate]
  );

  const initialValues = React.useMemo((): RecursivePartial<yup.InferType<typeof schema>> => {
    if (promotion.data) {
      const {
        name,
        description,
        imageURL,
        brandLogoURL,
        beneficiaryKind,
        segments,
        startAt,
        endAt,
        repeatable,
        repeatCount,
        repetition,
        ruleGroups,
        ruleGroupsState,
        target,
        tags,
        notificationConfig
      } = promotion.data;

      return {
        name,
        description,
        imageURL,
        brandLogoURL,
        beneficiaryKind,
        segments,
        startAt: new Date(startAt),
        endAt: new Date(endAt),
        repeatable,
        repeatCount,
        repetition,
        ruleGroups,
        ruleGroupsState: fromRuleGroupState(ruleGroupsState),
        target: { ...target, levels: target.levels.map((l) => ({ ...l, value: parseFloat(l.value) })) },
        tags,
        notificationConfig: {
          ...notificationConfig,
          channels: notificationConfig.channels ?? [NotificationChannel.Email, NotificationChannel.Sms],
          kinds: notificationConfig.kinds ?? [],
          forEachPeriod: !!notificationConfig.forEachPeriod
        }
      };
    }

    return {
      name: undefined,
      imageURL: undefined,
      brandLogoURL: undefined,
      beneficiaryKind: BeneficiaryKind.Client,
      segments: [],
      startAt: dayjs().add(1, 'day').startOf('day').toDate(),
      repetition: WITHOUT_REPETITION,
      ruleGroupsState: false,
      ruleGroups: [{ rules: [] }],
      target: { targetMu: TargetMu.Currency, levels: [{ bonus: { mu: BonusMu.Points } }] },
      tags: {
        [PromotionArticleTag.Car]: PromotionArticleTag.Car,
        [PromotionArticleTag.Motorcycle]: PromotionArticleTag.Motorcycle,
        [PromotionArticleTag.Truck]: PromotionArticleTag.Truck
      },
      notificationConfig: {
        channels: [NotificationChannel.Email, NotificationChannel.Sms],
        kinds: [NotificationKind.PromotionStart, NotificationKind.PromotionHalfTime, NotificationKind.PromotionEnd],
        forEachPeriod: false
      }
    };
  }, [promotion.data]);

  const selectSegmentModal = useModal(SelectSegmentModal);
  const updateRuleModal = useModal(UpdateRuleModal);

  return (
    <main className="space-y-6">
      <div className="flex flex-col items-start">
        <DynamicBackButton />
        <PageHeading title={<FormattedMessage id={translations.pages.promotionCreate.title} />} />
      </div>

      {/* @ts-ignore */}
      <Form {...{ initialValues, schema, onSubmit }}>
        {({ invalid, values, submitting, submitFailed, submitError, handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Card className="flex flex-col gap-8 rounded-b-none">
              <div className="grid gap-4 xl:grid-cols-2">
                <div className="flex flex-col gap-4">
                  <ValidatedField
                    field={InputWithLabel}
                    id="name"
                    label={<FormattedMessage id={translations.fields.promotionName.label} />}
                    name="name"
                    placeholder={intl.formatMessage({ id: translations.fields.promotionName.placeholder })}
                    readOnly={submitting}
                    type="text"
                  />

                  <ValidatedField
                    field={InputWithLabel}
                    format={(value?: string) => value?.toUpperCase()}
                    id="group-cost"
                    label={<FormattedMessage id={translations.fields.promotionGroupCost.label} />}
                    name="tags.group_cost"
                    placeholder={intl.formatMessage({ id: translations.fields.promotionGroupCost.placeholder })}
                    readOnly={submitting}
                    type="text"
                  />

                  <ValidatedField
                    as="textarea"
                    className="flex flex-1 flex-col"
                    field={InputWithLabel}
                    fieldClassName="h-full"
                    id="description"
                    inputClassName="min-h-[10rem] h-full resize-y"
                    label={<FormattedMessage id={translations.fields.promotionDescription.label} />}
                    name="description"
                    placeholder={intl.formatMessage({ id: translations.fields.promotionDescription.placeholder })}
                    readOnly={submitting}
                    type="text"
                  />
                </div>

                <div className="flex flex-col gap-4">
                  <ValidatedField
                    field={InputWithLabel}
                    id="image"
                    input={({ onFocus, onBlur, ...props }: any) => (
                      <ImageUpload src={values.imageURL}>
                        {({ upload }) => (
                          <div>
                            <div className="text-sm">
                              <FormattedMessage id={translations.fields.promotionImage.placeholder} />
                            </div>

                            <FileInput
                              {...props}
                              accept="image/*"
                              className="inline-flex cursor-pointer disabled:cursor-default"
                              onChange={(files) =>
                                upload(files[0])
                                  .then((url) => form.change(props.name, url))
                                  .catch(() => void 0)
                              }
                            >
                              <Button appearance="transparent" as="div" className="-ml-2 px-2" type="button">
                                <FormattedMessage id={translations.buttons.select} />
                              </Button>
                            </FileInput>
                          </div>
                        )}
                      </ImageUpload>
                    )}
                    label={<FormattedMessage id={translations.fields.promotionImage.label} />}
                    name="imageURL"
                    readOnly={submitting}
                    type="file"
                  />

                  <ValidatedField
                    field={InputWithLabel}
                    id="logo"
                    input={({ onFocus, onBlur, ...props }: any) => (
                      <ImageUpload src={values.brandLogoURL}>
                        {({ upload }) => (
                          <div>
                            <div className="text-sm">
                              <FormattedMessage id={translations.fields.promotionLogo.placeholder} />
                            </div>

                            <FileInput
                              {...props}
                              accept="image/*"
                              className="inline-flex cursor-pointer disabled:cursor-default"
                              onChange={(files) =>
                                upload(files[0])
                                  .then((url) => form.change(props.name, url))
                                  .catch(() => void 0)
                              }
                            >
                              <Button appearance="transparent" as="div" className="-ml-2 px-2" type="button">
                                <FormattedMessage id={translations.buttons.select} />
                              </Button>
                            </FileInput>
                          </div>
                        )}
                      </ImageUpload>
                    )}
                    label={<FormattedMessage id={translations.fields.promotionLogo.label} />}
                    name="brandLogoURL"
                    readOnly={submitting}
                    type="file"
                  />
                </div>
              </div>

              <div className="grid gap-4 xl:grid-cols-2">
                <Group>
                  <GroupHeader>
                    <GroupIcon icon={UsersIcon} />
                    <FormattedMessage id={translations.pages.promotionDetail.groups.segment.title} />
                  </GroupHeader>

                  <div className="flex flex-col items-start gap-2">
                    <div>
                      <ValidatedField
                        field={InputWithLabel}
                        id="beneficiary-kind"
                        input={ButtonRadioInput}
                        inputClassName="h-10"
                        items={[BeneficiaryKind.Client, BeneficiaryKind.User].map((value) => ({
                          value,
                          name: intl.formatMessage({ id: translations.enum.promotionBeneficiaryKind[value] })
                        }))}
                        label="Acordarea bonusului"
                        name="beneficiaryKind"
                        readOnly={submitting}
                        type="radio"
                      />
                    </div>

                    <PromotionSegments
                      segments={values.segments}
                      onDelete={(segment) =>
                        form.change(
                          'segments',
                          values.segments.filter((s) => s.id !== segment.id)
                        )
                      }
                    />

                    <Button
                      appearance="outline"
                      className="inline-flex h-10 px-4"
                      type="button"
                      onClick={() =>
                        selectSegmentModal
                          .open({ segments: values.segments.slice() })
                          .then(({ segments }) => form.change('segments', segments))
                          .catch(() => void 0)
                      }
                    >
                      <FormattedMessage id={translations.buttons.selectAudience} />
                    </Button>
                  </div>
                </Group>

                <Group>
                  <GroupHeader>
                    <GroupIcon icon={CalendarIcon} />
                    <FormattedMessage id={translations.pages.promotionDetail.groups.period.title} />
                  </GroupHeader>

                  <div className="grid max-w-52 gap-2">
                    <ValidatedField
                      as={DatePickerInput}
                      field={InputWithLabel}
                      id="start-at"
                      label={<FormattedMessage id={translations.fields.promotionPeriodStart.label} />}
                      max={values.endAt}
                      name="startAt"
                      placeholder={intl.formatMessage({ id: translations.fields.promotionPeriodStart.placeholder })}
                      readOnly={submitting}
                      type="text"
                    />

                    <ValidatedField
                      as={DatePickerInput}
                      field={InputWithLabel}
                      id="end-at"
                      label={<FormattedMessage id={translations.fields.promotionPeriodEnd.label} />}
                      min={values.startAt}
                      name="endAt"
                      parse={(value?: Date) => (value instanceof Date ? dayjs(value).endOf('day').toDate() : value)}
                      placeholder={intl.formatMessage({ id: translations.fields.promotionPeriodEnd.placeholder })}
                      readOnly={submitting}
                      type="text"
                      view={values.endAt ?? values.startAt}
                    />

                    <div>
                      <ValidatedField
                        as={Select}
                        field={InputWithLabel}
                        id="repetition"
                        items={[
                          {
                            value: WITHOUT_REPETITION,
                            name: intl.formatMessage({ id: translations.enum.repetition.none })
                          },
                          ...Object.values(Repetition).map((v) => ({
                            value: v,
                            name: intl.formatMessage({ id: translations.enum.repetition[v] })
                          }))
                        ]}
                        label={<FormattedMessage id={translations.fields.promotionFrequency.label} />}
                        name="repetition"
                        placeholder={intl.formatMessage({ id: translations.fields.promotionFrequency.placeholder })}
                        readOnly={submitting}
                        type="text"
                      />
                    </div>
                  </div>
                </Group>
              </div>

              <Group>
                <GroupHeader>
                  <GroupIcon icon={PuzzlePieceIcon} />
                  <FormattedMessage id={translations.pages.promotionDetail.groups.conditions.title} />
                </GroupHeader>

                <div className="grid gap-4">
                  <div>
                    <ValidatedField field={Checkbox} id="rule-group-state" name="ruleGroupsState" type="checkbox">
                      <FormattedMessage id={translations.pages.promotionDetail.groups.conditions.public} />
                    </ValidatedField>
                  </div>

                  <ul className="flex flex-col gap-4">
                    {values.ruleGroups.map((group, index, groups) => (
                      <React.Fragment key={index}>
                        {index > 0 && (
                          <li>
                            <RuleGroupSeparator />
                          </li>
                        )}

                        <li>
                          <RuleGroupItem
                            name={<FormattedMessage id={translations.pages.promotionCreate.groupName} values={{ index: index + 1 }} />}
                            onDelete={
                              groups.length > 1
                                ? () =>
                                    form.change(
                                      'ruleGroups',
                                      values.ruleGroups.filter((r) => r !== group)
                                    )
                                : undefined
                            }
                          >
                            <ul className="flex flex-wrap gap-4">
                              {group.rules.map((rule, index) => (
                                <React.Fragment key={index}>
                                  {index > 0 && (
                                    <li>
                                      <ConditionSeparator negation={!!rule.isNegation} />
                                    </li>
                                  )}

                                  <li>
                                    <RuleItem
                                      negation={!!rule.isNegation}
                                      operator={rule.tagKey}
                                      tags={ensureArray(rule.value?.value ?? []).map((v) => v)}
                                      onDelete={() => {
                                        const ruleGroups = values.ruleGroups.slice();
                                        const rules = group.rules.filter((r) => r !== rule);
                                        ruleGroups.splice(ruleGroups.indexOf(group), 1, { ...group, rules });
                                        form.change('ruleGroups', ruleGroups);
                                      }}
                                      onUpdate={() =>
                                        updateRuleModal
                                          .open({ rule })
                                          .then(({ rule }) => {
                                            const ruleGroups = values.ruleGroups.slice();
                                            const rules = group.rules.slice();
                                            rules.splice(index, 1, { ...rule });
                                            ruleGroups.splice(ruleGroups.indexOf(group), 1, { ...group, rules });
                                            form.change('ruleGroups', ruleGroups);
                                          })
                                          .catch(() => void 0)
                                      }
                                    />
                                  </li>
                                </React.Fragment>
                              ))}

                              {[false, ...(group.rules.length > 0 ? [true] : [])].map((isNegation) => (
                                <li key={isNegation.toString()}>
                                  <Button
                                    appearance="outline"
                                    className="h-full min-h-10 px-4"
                                    type="button"
                                    onClick={() =>
                                      updateRuleModal
                                        .open({
                                          rule: { kind: RuleKind.Tag, isNegation, condition: Condition.Contains }
                                        })
                                        .then(({ rule }) => {
                                          const ruleGroups = values.ruleGroups.slice();
                                          ruleGroups.splice(index, 1, { ...group, rules: [...group.rules, rule] });
                                          form.change('ruleGroups', ruleGroups);
                                        })
                                        .catch(() => void 0)
                                    }
                                  >
                                    <PlusIcon className="mr-2 h-5 w-5" />
                                    <FormattedMessage id={translations.pages.promotionCreate.createCondition} values={{ isNegation }} />
                                  </Button>
                                </li>
                              ))}
                            </ul>
                          </RuleGroupItem>

                          <ValidatedField field={() => null} id="rule-groups" name={`ruleGroups[${index}].rules`} />
                        </li>
                      </React.Fragment>
                    ))}
                  </ul>

                  <div>
                    <Button
                      appearance="outline"
                      className="inline-flex h-10 px-4"
                      type="button"
                      onClick={() => form.change('ruleGroups', [...values.ruleGroups, { rules: [] }])}
                    >
                      <PlusIcon className="mr-2 h-5 w-5" />
                      <FormattedMessage id={translations.pages.promotionCreate.createGroup} />
                    </Button>
                  </div>

                  <div className="flex flex-wrap gap-4">
                    <ValidatedField
                      field={InputWithLabel}
                      fieldClassName="max-w-60"
                      id="measurement-unit"
                      input={Select}
                      items={Object.values(TargetMu).map((v) => ({
                        value: v,
                        name: intl.formatMessage({ id: translations.enum.promotionMeasurementUnit[v] })
                      }))}
                      label={<FormattedMessage id={translations.fields.unit.label} />}
                      name="target.targetMu"
                      placeholder={intl.formatMessage({ id: translations.fields.unit.placeholder })}
                      readOnly={submitting}
                      type="text"
                    />

                    <InputWithLabel input="div" label={<FormattedMessage id={translations.fields.promotionCategory.label} />}>
                      {Object.values(PromotionArticleTag).map((tag) => (
                        <ValidatedField
                          field={Checkbox}
                          format={(v?: string) => (v ? [v] : [])}
                          id={`tag-${tag}`}
                          key={tag}
                          name={`tags.${tag}`}
                          parse={(v: string[] | string) => (Array.isArray(v) ? v[0] : undefined)}
                          readOnly={submitting}
                          type="checkbox"
                          validated={false}
                          value={tag}
                        >
                          <div className="flex items-center gap-2">
                            <FormattedMessage id={translations.enum.promotionArticle[tag]} />
                            <PromotionArticle {...{ tag }} />
                          </div>
                        </ValidatedField>
                      ))}
                    </InputWithLabel>
                  </div>
                </div>
              </Group>

              {values.target.levels.map((level, index, levels) => (
                <Group key={index}>
                  <GroupHeader className="justify-between">
                    <div className="flex items-center gap-2">
                      <GroupIcon icon={StarIcon} />

                      <FormattedMessage id={translations.pages.promotionCreate.targetLevelName} values={{ index: index + 1 }} />
                    </div>

                    {levels.length > 1 && (
                      <button
                        className="hover:bg-shade -m-1 inline-flex h-8 w-8 items-center justify-center rounded"
                        type="button"
                        onClick={() => {
                          form.change('target', { ...values.target, levels: levels.filter((l) => l !== level) });
                        }}
                      >
                        <XMarkIcon className="h-5 w-5" />
                      </button>
                    )}
                  </GroupHeader>

                  <div className="flex flex-wrap gap-4">
                    <ValidatedField
                      field={InputWithLabel}
                      fieldClassName="max-w-40"
                      id={`target-level-${index}-value`}
                      label={<FormattedMessage id={translations.fields.promotionTargetQuantity.label} />}
                      name={`target.levels[${index}].value`}
                      placeholder={intl.formatMessage({ id: translations.fields.promotionTargetQuantity.placeholder })}
                      readOnly={submitting}
                      type="text"
                    />

                    <ValidatedField
                      field={InputWithLabel}
                      id={`target-level-${index}-bonus-type`}
                      input={ButtonRadioInput}
                      inputClassName="h-10"
                      items={[BonusMu.Points, BonusMu.Product].map((value) => ({
                        value,
                        name: intl.formatMessage({ id: translations.enum.promotionBonusKind[value] })
                      }))}
                      label={<FormattedMessage id={translations.fields.promotionTargetBonus.label} />}
                      name={`target.levels[${index}].bonus.mu`}
                      readOnly={submitting}
                      type="radio"
                    />

                    {level.bonus.mu === BonusMu.Product ? (
                      <ValidatedField
                        field={InputWithLabel}
                        getItemsByIds={(ids: string[]) =>
                          getAllFromApi(
                            (nextToken) =>
                              productApi
                                .listProducts(
                                  ProductKind.Item,
                                  undefined,
                                  undefined,
                                  ProductUsage.Promotion,
                                  ProductStatus.Active,
                                  undefined,
                                  undefined,
                                  nextToken,
                                  ids
                                )
                                .then((r) => r.data),
                            (r) => r.data
                          ).then((items) => items.map(({ id, name }) => ({ value: id, name })))
                        }
                        getItemsBySearch={(search: string) =>
                          productApi
                            .listProducts(ProductKind.Item, undefined, search, ProductUsage.Promotion, ProductStatus.Active, undefined)
                            .then((r) => r.data.data)
                            .then((items) => items.map(({ id, name }) => ({ value: id, name })))
                        }
                        id={`target-level-${index}-bonus-product`}
                        input={AsyncSelect}
                        inputClassName="max-w-60 h-10"
                        label={<FormattedMessage id={translations.fields.promotionTargetProduct.label} />}
                        name={`target.levels[${index}].bonus.ref`}
                        placeholder={intl.formatMessage({ id: translations.fields.promotionTargetProduct.placeholder })}
                        readOnly={submitting}
                      />
                    ) : (
                      <ValidatedField
                        field={InputWithLabel}
                        id={`target-level-${index}-bonus-points`}
                        input={CoinInput}
                        inputClassName="max-w-40"
                        label={<FormattedMessage id={translations.fields.promotionTargetPoint.label} />}
                        name={`target.levels[${index}].bonus.value`}
                        placeholder={intl.formatMessage({ id: translations.fields.promotionTargetPoint.placeholder })}
                        readOnly={submitting}
                      />
                    )}
                  </div>
                </Group>
              ))}

              <div>
                <Button
                  appearance="outline"
                  className="inline-flex h-10 px-4"
                  type="button"
                  onClick={() =>
                    form.change('target', {
                      ...values.target,
                      levels: [
                        ...values.target.levels,
                        {
                          value: undefined as unknown as number,
                          bonus: {
                            value: undefined,
                            ref: undefined,
                            mu: values.target.levels[values.target.levels.length - 1].bonus.mu ?? BonusMu.Points
                          }
                        }
                      ]
                    })
                  }
                >
                  <PlusIcon className="mr-2 h-5 w-5" />
                  <FormattedMessage id={translations.pages.promotionCreate.createTargetLevel} />
                </Button>
              </div>

              <div>
                <ValidatedField field={Checkbox} id="multibonus" name="repeatable" type="checkbox">
                  <div>
                    <FormattedMessage id={translations.pages.promotionDetail.multibonus.label} />
                  </div>
                  <div className="text-secondary">
                    <FormattedMessage id={translations.pages.promotionDetail.multibonus.description} />
                  </div>
                </ValidatedField>
              </div>

              {values.repeatable && (
                <div>
                  <ValidatedField
                    as={NumberInput}
                    className="max-w-40"
                    field={InputWithLabel}
                    id="multibonus-limit"
                    label={<FormattedMessage id={translations.fields.promotionTargetMultiplierLimit.label} />}
                    name="repeatCount"
                    placeholder={intl.formatMessage({
                      id: translations.fields.promotionTargetMultiplierLimit.placeholder
                    })}
                    readOnly={submitting}
                  />
                </div>
              )}

              <Group>
                <GroupHeader className="justify-between">
                  <div className="flex items-center gap-2">
                    <GroupIcon icon={BellIcon} />

                    <FormattedMessage id={translations.pages.promotionDetail.groups.notifications} />
                  </div>
                </GroupHeader>

                <div className="flex flex-col gap-4">
                  <div>
                    {[NotificationKind.PromotionStart, NotificationKind.PromotionHalfTime, NotificationKind.PromotionEnd].map((kind) => (
                      <ValidatedField
                        field={Checkbox}
                        id={`notification-${kind}`}
                        key={kind}
                        name="notificationConfig.kinds"
                        readOnly={submitting}
                        type="checkbox"
                        validated={false}
                        value={kind}
                      >
                        <FormattedMessage id={translations.enum.notificationKind[kind]} />
                      </ValidatedField>
                    ))}
                  </div>

                  {values.repetition !== WITHOUT_REPETITION && (
                    <ValidatedField
                      field={Checkbox}
                      id="notification-each-period"
                      name="notificationConfig.forEachPeriod"
                      readOnly={submitting}
                      type="checkbox"
                      validated={false}
                    >
                      <FormattedMessage id={translations.fields.promotionNotificationEachPeriod.label} />
                    </ValidatedField>
                  )}
                </div>
              </Group>
            </Card>

            <div className="border-t-shade sticky bottom-0 rounded-b-lg border-t-2 bg-white p-4">
              <div className="flex items-center justify-between">
                <SubmitError error={submitError} />

                {submitFailed && invalid && !submitError && (
                  <ValidationMessage visible>
                    <FormattedMessage id={translations.pages.promotionDetail.invalid} />
                  </ValidationMessage>
                )}

                <div className="flex flex-1 justify-end gap-4">
                  <LoadingButton appearance="primary" className="h-14 px-4" loading={submitting} type="submit">
                    <FormattedMessage id={translations.buttons.save} />
                  </LoadingButton>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>

      <Modal {...selectSegmentModal.props} />
      <Modal {...updateRuleModal.props} />
    </main>
  );
};

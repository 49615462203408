import { useAsyncFn, useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  DeletableTag,
  FileInput,
  Form,
  InstanceProps,
  LoadingButton,
  ModalHeader,
  ModalLayout,
  ModalPrimaryButton,
  ModalSecondaryButton,
  SearchableSelect,
  SubmitError
} from '@binhatch/ui';
import { getAllFromApi } from '@binhatch/utility';
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import { Segment } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { segmentApi } from '@/integrations/api';
import { getItemsFromCsv } from '@/utils/csv';

interface Props extends InstanceProps<{ segments: Segment[] }, { segments: Segment[] }> {}

const schema = yup
  .object({
    segments: yup.array().of(yup.mixed<Segment>().required()).min(1).required().label(translations.fields.firstName.label)
  })
  .required();

export const SelectSegmentModal = React.forwardRef<HTMLDivElement, Props>(({ data, initialFocus, className, onAction, onClose }, ref) => {
  const intl = useIntl();

  const segments = useRemoteData({ key: 'useSegments' }, () =>
    getAllFromApi(
      (nextPage) => segmentApi.listSegments(nextPage).then((r) => r.data),
      (r) => r.segments
    )
  );

  const [{ loading: creating, error: uploadError }, create] = useAsyncFn(async (files: File[]) => {
    return await files.reduce<Promise<Segment[]>>(async (promise, file) => {
      const segments = await promise;
      const clients = await getItemsFromCsv(file, { referenceId: (v) => v?.trim() }).then((i) =>
        i.map((i) => i.referenceId).filter((i): i is string => !!i?.length)
      );

      const segment = await segmentApi.createSegment({ alias: file.name, clients }).then((r) => r.data);

      return [...segments, segment];
    }, Promise.resolve([]));
  });

  const initialValues = React.useMemo<yup.InferType<typeof schema>>(() => ({ segments: data.segments ?? [] }), [data.segments]);

  const onSubmit = React.useCallback(async ({ segments }: yup.InferType<typeof schema>) => onAction({ segments }), [onAction]);

  return (
    <div {...{ ref }} className={classnames(className, 'max-w-xl')}>
      <ModalLayout>
        <ModalHeader {...{ onClose }}>
          <FormattedMessage id={translations.modals.selectSegment.title} />
        </ModalHeader>

        <Form {...{ schema, initialValues, onSubmit }}>
          {({ values, invalid, submitting, submitError, handleSubmit, form }) => (
            <form className="m-0 grid gap-4" onSubmit={handleSubmit}>
              <div className="flex items-center gap-2">
                <SearchableSelect
                  containerClassName="flex-1"
                  id="segment-name"
                  items={segments?.data?.filter((s) => !values.segments.includes(s))?.map((s) => ({ value: s.id, name: s.alias })) ?? []}
                  name="segment-name"
                  placeholder={intl.formatMessage({ id: translations.fields.searchSegment.placeholder })}
                  readOnly={!!submitting}
                  onChange={(value: string) => form.change('segments', [...values.segments, ...(segments?.data?.filter((s) => s.id === value) ?? [])])}
                />

                <FileInput
                  accept="text/plain,text/csv,application/vnd.ms-excel,text/x-csv"
                  className="cursor-pointer"
                  disabled={creating}
                  onChange={(files) =>
                    create(files)
                      .then((segments) => {
                        form.change('segments', [...values.segments, ...segments]);
                      })
                      .catch(() => void 0)
                  }
                >
                  <LoadingButton as="div" className="h-10 w-10 md:w-auto md:px-4" loading={creating}>
                    <div className="flex items-center gap-2">
                      <ArrowUpOnSquareIcon className="h-5 w-5" />
                      <span className="hidden md:inline">
                        <FormattedMessage id={translations.buttons.uploadCsv} />
                      </span>
                    </div>
                  </LoadingButton>
                </FileInput>
              </div>

              {!!values.segments.length && (
                <ul className="flex flex-wrap items-center gap-2">
                  {values.segments.map((segment) => (
                    <li key={segment.id}>
                      <DeletableTag
                        onDelete={() =>
                          form.change(
                            'segments',
                            values.segments.filter((s) => s !== segment)
                          )
                        }
                      >
                        {segment?.alias}
                      </DeletableTag>
                    </li>
                  ))}
                </ul>
              )}

              <SubmitError error={submitError ?? uploadError} />

              <div className="flex flex-row-reverse space-x-2">
                <ModalPrimaryButton disabled={invalid} ref={initialFocus} onAction={() => handleSubmit()}>
                  <FormattedMessage id={translations.buttons.selectAudience} />
                </ModalPrimaryButton>

                <ModalSecondaryButton {...{ onClose }}>
                  <FormattedMessage id={translations.buttons.back} />
                </ModalSecondaryButton>
              </div>
            </form>
          )}
        </Form>
      </ModalLayout>
    </div>
  );
});

import { QueryParamConfig, decodeArrayQueryParam, decodeNumber, decodeSingleQueryParam, useQueryParams, useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { Banner, Button, CoinValue, LoadingState, PageLoaderHeading, Pagination, ProductImage, SearchInput, SearchableSelect } from '@binhatch/ui';
import { ProductKind, ProductStatus } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { categoryApi, productApi } from '@/integrations/api';
import { urls } from '@/utils/url';

import { usePendigProducts } from './hooks/usePendingProducts';

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const category: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const price: QueryParamConfig<[number, number] | undefined> = {
  decode: (value) => {
    const array = decodeArrayQueryParam(value, undefined) ?? [];
    return array.length === 2 ? [decodeNumber(array[0], 0), decodeNumber(array[1], 0)] : undefined;
  },
  encode: (value) => (value ? value : undefined)
};

const search: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => (value ? value : undefined)
};

const config = { page, category, price, search };

export const ShopManagerPage: React.FC = () => {
  const intl = useIntl();
  const [query, updateQuery] = useQueryParams({ config });

  const categories = useRemoteData({ key: `useCategories` }, async () => categoryApi.listCategories().then((r) => r.data.categories));

  const pending = usePendigProducts();

  const products = useRemoteData(
    {
      key: `useShopProducts`,
      category: query.category,
      price: query.price,
      search: query.search,
      page: query.page,
      skip: categories.isLoading || categories.isValidating
    },
    async ({ category, search, page: nextToken }) =>
      productApi.listProducts(ProductKind.Item, category, search, undefined, ProductStatus.Active, undefined, undefined, nextToken).then((r) => r.data)
  );

  return (
    <React.Fragment>
      <main className="space-y-6">
        <div className="flex flex-col justify-between gap-4 md:flex-row md:items-center">
          <PageLoaderHeading loading={products.isLoading || products.isValidating}>
            <FormattedMessage id={translations.pages.shop.title.system} />
          </PageLoaderHeading>

          <div className="flex items-center gap-4">
            <SearchInput
              className="w-72"
              placeholder={intl.formatMessage({ id: translations.pages.shop.search })}
              value={query.search}
              onChange={(search: string) => updateQuery({ page: undefined, search })}
            />

            <SearchableSelect
              containerClassName="w-72"
              items={[
                { value: undefined, name: intl.formatMessage({ id: translations.pages.productList.allCategories }) },
                ...(categories.data?.map((category) => ({ value: category.id, name: category.name })) ?? [])
              ]}
              placeholder="Cauta categorie"
              value={query.category}
              onChange={(category?: string) => updateQuery({ page: undefined, category })}
            />
          </div>
        </div>

        {!!pending.data?.data.length && (
          <Banner className="flex-col md:flex-row md:items-center md:justify-between">
            <div>
              <div className="font-semibold">
                <FormattedMessage id={translations.pages.productList.review.title} />
              </div>

              <div>
                <FormattedMessage id={translations.pages.productList.review.message} />
              </div>
            </div>

            <Button appearance="secondary" as={Link} className="h-10 px-4" state={{ from: 1 }} to={urls.shop.products.review}>
              <FormattedMessage id={translations.pages.productList.review.cta} />
            </Button>
          </Banner>
        )}

        <LoadingState loading={products.isLoading || products.isValidating}>
          <div className="flex flex-col items-start gap-4 lg:flex-row">
            <ul className="grid min-h-[7rem] w-full flex-1 gap-4 sm:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5">
              {products.data?.data.map((product) => (
                <li className="relative flex flex-col rounded-lg bg-white shadow" key={product.id}>
                  <Link
                    className="rounded-t-lgp-4 relative block w-full space-y-2 overflow-hidden"
                    state={{ from: 1 }}
                    to={urls.shop.products.getOne({ productId: product.id }, urls.shop.products.one)}
                  >
                    <ProductImage className="w-full" square src={product.media[0]} />

                    <div className="-mt-4 flex flex-1 flex-col gap-1 rounded-b-lg p-4">
                      <div className="flex max-w-full flex-1 items-end font-semibold">{product.name}</div>

                      <div className="flex items-center justify-between">
                        <CoinValue value={product.value} />
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </LoadingState>

        <Pagination
          hasNext={!!products.data?.nextToken}
          // hasPrevious={!!tales.data?.previousToken}
          onNext={() => updateQuery({ page: products.data?.nextToken })}
          // onPrevious={() => updateQuery({ page: tales.data?.previousToken })}
        />
      </main>
    </React.Fragment>
  );
};

import { readFileAsText } from '@binhatch/utility';
import papa from 'papaparse';

export const getItemsFromCsv = async <M extends { [x: string]: (value?: string) => any }>(
  file: File,
  mapper: M
): Promise<{ [x in keyof M]: ReturnType<M[x]> }[]> => {
  const content = await readFileAsText(file);

  const csv = papa.parse<[string, string]>(content, { download: false });

  const mappers = Object.entries(mapper);

  return csv.data.map((columns) => Object.fromEntries(mappers.map(([key, mapper], index) => [key, mapper(columns[index])]))) as {
    [x in keyof M]: ReturnType<M[x]>;
  }[];
};

import { translations } from '@binhatch/locale';
import {
  ButtonRadioInput,
  CoinInput,
  CoinValue,
  Form,
  InputWithLabel,
  InstanceProps,
  ModalHeader,
  ModalLayout,
  ModalPrimaryButton,
  ModalSecondaryButton,
  SubmitError,
  ValidatedField
} from '@binhatch/ui';
import classnames from 'classnames';
import { TransactionCurrencyKind, TransactionKind } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

interface Props
  extends InstanceProps<
    void,
    {
      balance: number;
      currency: TransactionCurrencyKind;
      onUpdate(o: { value: number; transactionKind: TransactionKind; currency: TransactionCurrencyKind }): Promise<void>;
    }
  > {}

const schema = yup
  .object({
    transactionKind: yup.mixed<TransactionKind>().required(),
    value: yup.number().min(1).required().label(translations.modals.allocateBalance.credit.value)
  })
  .required();

export const AllocateBalanceModal = React.forwardRef<HTMLDivElement, Props>(
  ({ data: { balance, currency, onUpdate }, initialFocus, className, onAction, onClose }, ref) => {
    const intl = useIntl();

    const initialValues = React.useMemo(() => ({ transactionKind: TransactionKind.Credit, value: undefined as unknown as number }), []);

    const onSubmit = React.useCallback(
      async ({ transactionKind, value }: yup.InferType<typeof schema>) => {
        await onUpdate({ transactionKind, value, currency });
        onAction();
      },
      [onUpdate, onAction, currency]
    );

    return (
      <div {...{ ref }} className={classnames(className, 'max-w-xl')}>
        <ModalLayout>
          <ModalHeader {...{ onClose }}>
            <FormattedMessage id={translations.modals.allocateBalance.title} values={{ currency }} />
          </ModalHeader>

          <Form {...{ schema, initialValues, onSubmit }} context={{ currency }}>
            {({ values, submitting, submitError, handleSubmit }) => (
              <form className="m-0 grid gap-4" onSubmit={handleSubmit}>
                <ValidatedField
                  field={ButtonRadioInput}
                  fieldClassName="h-10"
                  id="transaction-kind"
                  items={[TransactionKind.Credit, TransactionKind.Debit].map((value) => ({
                    value,
                    name: intl.formatMessage({ id: translations.modals.allocateBalance[value].type }, { currency })
                  }))}
                  name="transactionKind"
                  readOnly={submitting}
                  type="radio"
                />

                <div className="grid gap-x-4 gap-y-2 sm:grid-cols-3">
                  <ValidatedField
                    field={InputWithLabel}
                    id="value"
                    input={CoinInput}
                    inputClassName="w-full"
                    label={<FormattedMessage id={translations.modals.allocateBalance[values.transactionKind].value} values={{ currency }} />}
                    name="value"
                    placeholder="0"
                    readOnly={submitting}
                    ref={initialFocus}
                  />

                  <InputWithLabel
                    input={() => <CoinValue className="h-10" value={balance} />}
                    label={<FormattedMessage id={translations.modals.allocateBalance.current} values={{ currency }} />}
                  />

                  <InputWithLabel
                    input={() => (
                      <CoinValue className="h-10" value={balance + (values.value ?? 0) * (values.transactionKind === TransactionKind.Debit ? -1 : 1)} />
                    )}
                    label={<FormattedMessage id={translations.modals.allocateBalance.final} values={{ currency }} />}
                  />
                </div>

                <SubmitError error={submitError} />

                <div className="flex flex-row-reverse space-x-2">
                  <ModalPrimaryButton onAction={() => handleSubmit()}>
                    <FormattedMessage id={translations.modals.allocateBalance.cta} values={{ currency: values.transactionKind }} />
                  </ModalPrimaryButton>

                  <ModalSecondaryButton {...{ onClose }}>
                    <FormattedMessage id={translations.buttons.back} />
                  </ModalSecondaryButton>
                </div>
              </form>
            )}
          </Form>
        </ModalLayout>
      </div>
    );
  }
);

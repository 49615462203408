import { QueryParamConfig, decodeSingleQueryParam, useQueryParams, useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { Button, EmptyState, LoadingState, Modal, PageLoaderHeading, Pagination, SearchInput, useModal } from '@binhatch/ui';
import { BeneficiaryKind, TransactionCurrencyKind, UserRole, UserSource } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { balanceApi, userApi } from '@/integrations/api';

import { useBalances } from '@/hooks/useBalances';

import { Auth } from '@/containers/useAuth';
import { useGroupedBalances } from '@/hooks/useGroupedBalances';
import { BatchAllocateBalanceModal } from '@/modals/BatchAllocateBalanceModal';
import { BatchTransferBalanceModal } from '@/modals/BatchTransferBalanceModal';
import { SalesUserItem } from './components/SalesUserItem';

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const search: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => (value ? value : undefined)
};

const config = { page, search };

export const SalesUserListPage: React.FC = () => {
  const { context } = Auth.useContainer();

  const intl = useIntl();
  const [query, updateQuery] = useQueryParams({ config });

  const users = useRemoteData({ key: `useSalesUsers`, search: query.search, page: query.page }, async ({ search, page: nextToken }) => {
    return userApi.listUsers(nextToken, undefined, search, undefined, undefined, UserSource.System).then((r) => r.data);
  });

  const allocateBalanceModal = useModal(BatchAllocateBalanceModal);
  const transferBalanceModal = useModal(BatchTransferBalanceModal);

  const balances = useBalances({ ids: users.data?.users.map((u) => u.id), beneficiaryKind: BeneficiaryKind.User });
  const groupedBalances = useGroupedBalances(balances.data);

  return (
    <React.Fragment>
      <main className="space-y-6">
        <div className="flex flex-col flex-wrap justify-between gap-4 lg:flex-row lg:items-center">
          <PageLoaderHeading loading={users.isLoading || users.isValidating}>
            <FormattedMessage id={translations.pages.salesUserList.title} />
          </PageLoaderHeading>

          <div className="flex flex-col gap-4 md:flex-row md:items-center">
            <SearchInput
              className="w-full md:w-72"
              placeholder={intl.formatMessage({ id: translations.pages.salesUserList.search })}
              value={query.search}
              onChange={(search: string) => updateQuery({ page: undefined, search })}
            />

            <div className="flex gap-4">
              {!!context &&
                [TransactionCurrencyKind.SalesBudget, TransactionCurrencyKind.CustomDealBudget].map((currency) => (
                  <Button
                    appearance="secondary"
                    className="h-10 flex-1 whitespace-nowrap px-4"
                    key={currency}
                    onClick={() => {
                      if (context.user.role === UserRole.SystemAdmin)
                        return allocateBalanceModal
                          .open({
                            currency,
                            onUpdate: ({ transactionKind, currency, items }) =>
                              balanceApi
                                .bulkUpdateBalances({
                                  requests: items.map(({ id, amount }) => ({
                                    amount,
                                    beneficiaryReference: id,
                                    beneficiaryKind: BeneficiaryKind.User,
                                    transactionKind,
                                    currency
                                  }))
                                })
                                .then(() => void 0)
                          })
                          .then(() => balances.mutate())
                          .catch(() => void 0);

                      return transferBalanceModal
                        .open({
                          currency,
                          onUpdate: ({ transactionKind, currency, items }) =>
                            balanceApi
                              .transferBalanceBulk({
                                currency,
                                beneficiaries: items.map(({ id, amount }) => ({
                                  amount,
                                  beneficiaryReference: id,
                                  beneficiaryKind: BeneficiaryKind.User,
                                  transactionKind
                                }))
                              })
                              .then(() => void 0)
                        })
                        .then(() => balances.mutate())
                        .catch(() => void 0);
                    }}
                  >
                    <FormattedMessage
                      id={context.user.role === UserRole.SystemAdmin ? translations.modals.allocateBalance.title : translations.modals.transferBalance.title}
                      values={{ currency }}
                    />
                  </Button>
                ))}
            </div>
          </div>
        </div>

        <LoadingState loading={users.isLoading || users.isValidating}>
          {!users.isLoading && !users.data?.users.length && (
            <EmptyState>
              <FormattedMessage id={translations.pages.userList.empty} />
            </EmptyState>
          )}

          <ul className="flex flex-col gap-4">
            {users.data?.users.map((user, index) => (
              <li key={index}>
                <SalesUserItem {...{ user }} balance={groupedBalances?.get(user.id)} />
              </li>
            ))}
          </ul>
        </LoadingState>

        <Pagination
          hasNext={!!users.data?.nextToken}
          // hasPrevious={!!tales.data?.previousToken}
          onNext={() => updateQuery({ page: users.data?.nextToken })}
          // onPrevious={() => updateQuery({ page: tales.data?.previousToken })}
        />
      </main>

      <Modal {...allocateBalanceModal.props} />
      <Modal {...transferBalanceModal.props} />
    </React.Fragment>
  );
};

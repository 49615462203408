import { QueryParamConfig, decodeSingleQueryParam, useQueryParams, useRemoteData, useStrictParams } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { Button, CoinValue, EmptyState, LoadingState, Modal, Pagination, useModal } from '@binhatch/ui';
import { BeneficiaryKind, TransactionCurrencyKind } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { balanceApi, userApi } from '@/integrations/api';

import { useBalances } from '@/hooks/useBalances';

import { Auth } from '@/containers/useAuth';
import { useGroupedBalances } from '@/hooks/useGroupedBalances';
import { AllocateBalanceModal } from '@/modals/AllocateBalanceModal';
import { ClientUserItem } from './components/ClientUserItem';

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const search: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => (value ? value : undefined)
};

const config = { page, search };

export const ClientUserListPage: React.FC = () => {
  const { context } = Auth.useContainer();
  const { clientId } = useStrictParams<{ clientId: string }>();
  const [query, updateQuery] = useQueryParams({ config });

  const allocateBalanceModal = useModal(AllocateBalanceModal);

  const users = useRemoteData({ key: `useClientUsers`, clientId, search: query.search, page: query.page }, async ({ search, clientId, page: nextToken }) => {
    return userApi.listUsers(nextToken, undefined, search, [clientId!]).then((r) => r.data);
  });

  const balances = useBalances({ ids: users.data?.users.map((u) => u.id), beneficiaryKind: BeneficiaryKind.User });
  const groupedBalances = useGroupedBalances(balances.data);

  return (
    <React.Fragment>
      <div className="space-y-6">
        <LoadingState loading={users.isLoading || users.isValidating}>
          {!users.isLoading && !users.data?.users.length && (
            <EmptyState>
              <FormattedMessage id={translations.pages.userList.empty} />
            </EmptyState>
          )}

          <ul className="flex flex-col gap-4">
            {users.data?.users.map((user, index) => (
              <li key={index}>
                <ClientUserItem {...{ user }}>
                  <div className="flex flex-col items-end">
                    <div className="flex items-center gap-2">
                      <FormattedMessage id={translations.enum.balance[BeneficiaryKind.User]} />

                      <div className="flex flex-1 justify-end">
                        <CoinValue value={groupedBalances?.get(user.id)?.user?.amount ?? 0} />
                      </div>
                    </div>

                    {!!context && (
                      <Button
                        appearance="transparent"
                        className="py-2"
                        onClick={() =>
                          allocateBalanceModal
                            .open({
                              balance: groupedBalances?.get(user.id)?.user?.amount ?? 0,
                              currency: TransactionCurrencyKind.Points,
                              onUpdate: ({ value: amount, transactionKind, currency }) =>
                                balanceApi
                                  .updateBalance(user.id, {
                                    amount,
                                    beneficiaryKind: BeneficiaryKind.User,
                                    transactionKind,
                                    currency
                                  })
                                  .then(() => void 0)
                            })
                            .then(() => balances.mutate())
                            .catch(() => void 0)
                        }
                      >
                        <FormattedMessage id={translations.pages.clientDetail.updateBalance} />
                      </Button>
                    )}
                  </div>
                </ClientUserItem>
              </li>
            ))}
          </ul>
        </LoadingState>

        <Pagination
          hasNext={!!users.data?.nextToken}
          // hasPrevious={!!tales.data?.previousToken}
          onNext={() => updateQuery({ page: users.data?.nextToken })}
          // onPrevious={() => updateQuery({ page: tales.data?.previousToken })}
        />
      </div>

      <Modal {...allocateBalanceModal.props} />
    </React.Fragment>
  );
};

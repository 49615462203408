import { translations } from '@binhatch/locale';
import { Card, ConditionSeparator, PromotionSegments, RuleGroupItem, RuleGroupSeparator, RuleItem } from '@binhatch/ui';
import { ensureArray } from '@binhatch/utility';
import { PuzzlePieceIcon, UsersIcon } from '@heroicons/react/24/outline';
import { Promotion, RuleGroupState } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useOutletContext } from 'react-router';

import { useTags } from '@/hooks/useTags';

export const PromotionConfigurationPage: React.FC = () => {
  const promotion = useOutletContext<Promotion>();
  const tags = useTags();

  return (
    <Card className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <div className="bg-shade-light flex items-center gap-2 rounded px-4 py-3 font-semibold">
          <UsersIcon className="h-5 w-5" />
          <FormattedMessage id={translations.pages.promotionDetail.groups.segment.title} />
        </div>

        <PromotionSegments segments={promotion.segments} />

        <div>
          <div>
            <FormattedMessage id={translations.pages.promotionDetail.groups.segment.type} values={{ type: promotion.beneficiaryKind }} />
          </div>

          {!!promotion.repeatable && (
            <div>
              <FormattedMessage id={translations.pages.promotionDetail.multibonus.description} />{' '}
              <FormattedMessage id={translations.fields.promotionTargetMultiplierLimit.label} />: {promotion.repeatCount}
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="bg-shade-light flex items-center gap-2 rounded px-4 py-3 font-semibold">
          <PuzzlePieceIcon className="h-5 w-5" />
          <FormattedMessage id={translations.pages.promotionDetail.groups.conditions.title} />
        </div>

        {promotion.ruleGroupsState === RuleGroupState.Visible && (
          <div>
            <FormattedMessage id={translations.pages.promotionDetail.groups.conditions.public} />
          </div>
        )}

        <ul className="flex flex-col gap-4">
          {promotion.ruleGroups.map((group, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <li>
                  <RuleGroupSeparator />
                </li>
              )}

              <li>
                <RuleGroupItem name={<FormattedMessage id={translations.pages.promotionCreate.groupName} values={{ index: index + 1 }} />}>
                  <ul className="flex flex-wrap gap-4">
                    {group.rules.map((rule, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && (
                          <li>
                            <ConditionSeparator negation={!!rule.isNegation} />
                          </li>
                        )}

                        <li>
                          <RuleItem
                            negation={!!rule.isNegation}
                            operator={tags.data?.find((tag) => tag.key === rule.tagKey)?.description ?? rule.tagKey}
                            tags={ensureArray(rule.value?.value ?? []).map((v) => v)}
                          />
                        </li>
                      </React.Fragment>
                    ))}
                  </ul>
                </RuleGroupItem>
              </li>
            </React.Fragment>
          ))}
        </ul>
      </div>
    </Card>
  );
};

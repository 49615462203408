import { useAsyncFn } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  ButtonRadioInput,
  FileInput,
  Form,
  FormattedValidationMessage,
  InstanceProps,
  LoadingButton,
  ModalHeader,
  ModalLayout,
  ModalSecondaryButton,
  SubmitError,
  ValidatedField
} from '@binhatch/ui';
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import { TransactionCurrencyKind, TransactionKind } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { getItemsFromCsv } from '@/utils/csv';

interface Props
  extends InstanceProps<
    void,
    {
      currency: TransactionCurrencyKind;
      onUpdate: (o: { currency: TransactionCurrencyKind; transactionKind: TransactionKind; items: { id: string; amount: number }[] }) => Promise<void>;
    }
  > {}

const schema = yup
  .object({
    transactionKind: yup.mixed<TransactionKind>().required(),
    items: yup
      .array()
      .of(
        yup
          .object({
            id: yup.string().required().label(translations.fields.column.label),
            amount: yup.number().integer().required().label(translations.fields.column.label)
          })
          .required()
      )
      .min(1)
      .required()
      .label(translations.modals.allocateBalance.debit.value)
  })
  .required();

export const BatchAllocateBalanceModal = React.forwardRef<HTMLDivElement, Props>(({ data: { currency, onUpdate }, className, onAction, onClose }, ref) => {
  const intl = useIntl();

  const initialValues = React.useMemo(() => ({ transactionKind: TransactionKind.Credit, items: [] }), []);

  const onSubmit = React.useCallback(
    async ({ transactionKind, items }: yup.InferType<typeof schema>) => {
      await onUpdate({ currency, transactionKind, items });

      onAction();
    },
    [onAction, currency, onUpdate]
  );

  const [{ loading: parsing, error: parseError }, parseFile] = useAsyncFn((file: File) =>
    getItemsFromCsv(file, {
      id: (v) => v?.trim() ?? '',
      amount: (v) => (v ? parseInt(v.trim()) : 0)
    })
  );

  return (
    <div {...{ ref }} className={classnames(className, 'max-w-sm')}>
      <ModalLayout>
        <ModalHeader {...{ onClose }}>
          <FormattedMessage id={translations.modals.allocateBalance.title} values={{ currency }} />
        </ModalHeader>

        <Form {...{ schema, initialValues, onSubmit }} context={{ currency }}>
          {({ submitting, errors, submitError, handleSubmit, form }) => (
            <form className="m-0 grid gap-4" onSubmit={handleSubmit}>
              <ValidatedField
                field={ButtonRadioInput}
                fieldClassName="h-10"
                id="transaction-kind"
                items={[TransactionKind.Credit, TransactionKind.Debit].map((value) => ({
                  value,
                  name: intl.formatMessage({ id: translations.modals.allocateBalance[value].type }, { currency })
                }))}
                name="transactionKind"
                readOnly={submitting}
                type="radio"
              />

              {!!errors?.items?.length && (
                <ul className="flex flex-col gap-1">
                  {Array.from(errors?.items ?? [])
                    .slice(0, 10)
                    .map((error: any, index) => (
                      <li key={index}>
                        {[error.id, error.amount].filter(Boolean).map(({ id, values }, index) => (
                          <FormattedValidationMessage
                            key={index}
                            {...{ id }}
                            values={{
                              ...values,
                              rowIndex: parseInt(values.path.replace(/[^0-9]/g, '')) + 1,
                              columnIndex: index + 1
                            }}
                            visible
                          />
                        ))}
                      </li>
                    ))}
                </ul>
              )}

              <SubmitError error={submitError ?? parseError} />

              <div className="flex flex-row-reverse space-x-2">
                <FileInput
                  accept="text/plain,text/csv,application/vnd.ms-excel,text/x-csv"
                  className="inline-flex cursor-pointer disabled:cursor-default"
                  disabled={parsing}
                  onChange={([file]) =>
                    parseFile(file)
                      .then((items) => form.change('items', items))
                      .then(() => setTimeout(handleSubmit, 100))
                      .catch(() => void 0)
                  }
                >
                  <LoadingButton appearance="primary" as="div" className="h-10 px-4" loading={parsing || submitting}>
                    <div className="flex items-center gap-2">
                      <ArrowUpOnSquareIcon className="h-5 w-5" />
                      <FormattedMessage id={translations.buttons.uploadCsv} />
                    </div>
                  </LoadingButton>
                </FileInput>

                <ModalSecondaryButton {...{ onClose }}>
                  <FormattedMessage id={translations.buttons.back} />
                </ModalSecondaryButton>
              </div>
            </form>
          )}
        </Form>
      </ModalLayout>
    </div>
  );
});

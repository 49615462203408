import { QueryParamConfig, decodeSingleQueryParam, useQueryParams, useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { Button, EmptyState, LoadingState, Modal, PageLoaderHeading, Pagination, SearchInput, useModal } from '@binhatch/ui';
import { BeneficiaryKind, TransactionCurrencyKind } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { balanceApi, clientApi } from '@/integrations/api';

import { useBalances } from '@/hooks/useBalances';
import { useGroupedBalances } from '@/hooks/useGroupedBalances';

import { BatchAllocateBalanceModal } from '@/modals/BatchAllocateBalanceModal';

import { ClientItem } from './components/ClientItem';

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const search: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => (value ? value : undefined)
};

const config = { page, search };

export const ClientListPage: React.FC = () => {
  const intl = useIntl();
  const [query, updateQuery] = useQueryParams({ config });

  const allocateBalanceModal = useModal(BatchAllocateBalanceModal);

  const clients = useRemoteData({ key: `useClients`, search: query.search, page: query.page }, async ({ search, page: nextToken }) =>
    clientApi.listClients(nextToken, search).then((r) => r.data)
  );

  const balances = useBalances({
    ids: clients.data?.clients.map((u) => u.id),
    beneficiaryKind: BeneficiaryKind.Client
  });

  const groupedBalances = useGroupedBalances(balances.data);

  return (
    <React.Fragment>
      <main className="space-y-6">
        <div className="flex flex-col justify-between gap-4 md:flex-row md:items-center">
          <PageLoaderHeading loading={clients.isLoading || clients.isValidating}>
            <FormattedMessage id={translations.pages.clientList.title} />
          </PageLoaderHeading>

          <div className="flex items-center gap-4">
            <SearchInput
              className="w-full md:w-72"
              placeholder={intl.formatMessage({ id: translations.pages.clientList.search })}
              value={query.search}
              onChange={(search: string) => updateQuery({ page: undefined, search })}
            />

            <Button
              appearance="secondary"
              className="h-10 whitespace-nowrap px-4"
              onClick={() =>
                allocateBalanceModal
                  .open({
                    currency: TransactionCurrencyKind.Points,
                    onUpdate: async ({ transactionKind, currency, items }) => {
                      await balanceApi.bulkUpdateBalances({
                        requests: items.map(({ id, amount }) => ({
                          amount,
                          beneficiaryKind: BeneficiaryKind.Client,
                          beneficiaryReference: id,
                          transactionKind,
                          currency
                        }))
                      });
                    }
                  })
                  .then(() => balances.mutate())
                  .catch(() => void 0)
              }
            >
              <FormattedMessage id={translations.pages.clientDetail.updateBalance} />
            </Button>
          </div>
        </div>

        <LoadingState loading={clients.isLoading || clients.isValidating}>
          {!clients.isLoading && !clients.data?.clients.length && (
            <EmptyState>
              <FormattedMessage id={translations.pages.userList.empty} />
            </EmptyState>
          )}

          <ul className="flex flex-col gap-4">
            {clients.data?.clients.map((client) => (
              <li key={client.id}>
                <ClientItem {...{ client }} balance={groupedBalances?.get(client.id)?.client?.amount ?? 0} />
              </li>
            ))}
          </ul>
        </LoadingState>

        <Pagination
          hasNext={!!clients.data?.nextToken}
          // hasPrevious={!!tales.data?.previousToken}
          onNext={() => updateQuery({ page: clients.data?.nextToken })}
          // onPrevious={() => updateQuery({ page: tales.data?.previousToken })}
        />
      </main>

      <Modal {...allocateBalanceModal.props} />
    </React.Fragment>
  );
};

import { translations } from '@binhatch/locale';
import {
  CoinInput,
  CoinValue,
  Form,
  InputWithLabel,
  InstanceProps,
  ModalHeader,
  ModalLayout,
  ModalPrimaryButton,
  ModalSecondaryButton,
  SubmitError,
  ValidatedField
} from '@binhatch/ui';
import classnames from 'classnames';
import { TransactionCurrencyKind, TransactionKind } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

interface Props
  extends InstanceProps<
    void,
    {
      balance: number;
      currency: TransactionCurrencyKind;
      onUpdate(o: { value: number; transactionKind: TransactionKind; currency: TransactionCurrencyKind }): Promise<void>;
    }
  > {}

const schema = yup
  .object({
    value: yup.number().min(1).required().label(translations.modals.transferBalance.value)
  })
  .required();

export const TransferBalanceModal = React.forwardRef<HTMLDivElement, Props>(
  ({ data: { balance, currency, onUpdate }, initialFocus, className, onAction, onClose }, ref) => {
    const initialValues = React.useMemo(() => ({ value: undefined as unknown as number }), []);

    const onSubmit = React.useCallback(
      async ({ value }: yup.InferType<typeof schema>) => {
        await onUpdate({ value, currency, transactionKind: TransactionKind.Debit });
        onAction();
      },
      [onUpdate, onAction, currency]
    );

    return (
      <div {...{ ref }} className={classnames(className, 'max-w-xl')}>
        <ModalLayout>
          <ModalHeader {...{ onClose }}>
            <FormattedMessage id={translations.modals.transferBalance.title} values={{ currency }} />
          </ModalHeader>

          <Form {...{ schema, initialValues, onSubmit }} context={{ currency }}>
            {({ values, submitting, submitError, handleSubmit }) => (
              <form className="m-0 grid gap-4" onSubmit={handleSubmit}>
                <div className="grid gap-x-4 gap-y-2 sm:grid-cols-3">
                  <InputWithLabel
                    input={() => <CoinValue className="h-10" value={balance} />}
                    label={<FormattedMessage id={translations.modals.transferBalance.current} values={{ currency }} />}
                  />

                  <ValidatedField
                    field={InputWithLabel}
                    id="value"
                    input={CoinInput}
                    inputClassName="w-full"
                    label={<FormattedMessage id={translations.modals.transferBalance.value} values={{ currency }} />}
                    name="value"
                    placeholder="0"
                    readOnly={submitting}
                  />

                  <InputWithLabel
                    input={() => <CoinValue className="h-10" value={balance - (values.value ?? 0)} />}
                    label={<FormattedMessage id={translations.modals.transferBalance.final} values={{ currency }} />}
                  />
                </div>

                <SubmitError error={submitError} />

                <div className="flex flex-row-reverse space-x-2">
                  <ModalPrimaryButton ref={initialFocus} onAction={() => handleSubmit()}>
                    <FormattedMessage id={translations.modals.transferBalance.cta} />
                  </ModalPrimaryButton>

                  <ModalSecondaryButton {...{ onClose }}>
                    <FormattedMessage id={translations.buttons.back} />
                  </ModalSecondaryButton>
                </div>
              </form>
            )}
          </Form>
        </ModalLayout>
      </div>
    );
  }
);
